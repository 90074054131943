export const statusOptions = [
  { title: '未开始', value: 0 },
  { title: '进行中', value: 1 },
  { title: '已完成', value: 2 },
]

export const platformOptions = [
  { label: 'Youtube', value: 'youtube' },
  { label: 'Facebook', value: 'facebook' },
]

export const platformOptionsVo = [
  { label: 'Youtube', value: 'Youtube' },
  { label: 'Facebook', value: 'Facebook' },
]

export const domPlatformOptions = [
  { label: '西瓜', value: 'xigua' },
  { label: 'B站', value: 'bilibili' },
  { label: '抖音', value: 'douyin' },
  { label: '快手', value: 'kuaishou' },
  { label: '网易', value: 'wangyi' },
  { label: '好看', value: 'haokan' },
  { label: 'Youtube', value: 'youtube' },
]

export const typeOptions = [
  { label: '影视', value: 'slice' },
  { label: '原创自制', value: 'original_own' },
  { label: '代运营', value: 'acting' },
  { label: '原创代运营', value: 'original_acting' },
  { label: '自运营', value: 'self' },
]

// export const operationOptions = [
//   { label: '杭州FB运营组', value: 'hz_fb' },
//   { label: '晋城FB运营中心', value: 'jc_fb' },
//   { label: '晋城FB运营1组', value: 'jc_fb_1' },
//   { label: '晋城FB运营2组', value: 'jc_fb_2' },
//   { label: '晋城FB运营3组', value: 'jc_fb_3' },
//   { label: '杭州影视', value: 'hz_slice' },
//   { label: '晋城影视', value: 'jc_slice' },
//   { label: '杭州代运营', value: 'hz_mcn' },
//   { label: 'YT事业部', value: 'jc_mcn' },
//   { label: '杭州原创', value: 'hz_original' },
//   { label: '晋城原创', value: 'jc_original' },
//   { label: '上交公司', value: 'hand_over_company' },
//   { label: '郑州FB孵化组', value: 'zz_incubate' },
//   { label: '杭州FB孵化组', value: 'hz_incubate' },
//   { label: '濮阳FB孵化组', value: 'py_incubate' },
//   { label: '濮阳FB运营组', value: 'py_fb' },
//   { label: '晋城FB孵化组', value: 'jc_incubate' },
// ]

export const operationAddOptions = [
  { label: 'FB事业部', value: 'hz_fb' },
  { label: '晋城FB运营中心', value: 'jc_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '杭州代运营', value: 'hz_mcn' },
  { label: 'YT事业部', value: 'jc_mcn' },
  { label: '国际事业部', value: 'hz_original' },
  { label: '晋城原创', value: 'jc_original' },
  { label: '上交公司', value: 'hand_over_company' },
  { label: '郑州FB孵化组', value: 'zz_incubate' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '濮阳FB孵化组', value: 'py_incubate' },
  { label: '濮阳FB运营组', value: 'py_fb' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
  { label: '上交公司', value: 'hand_over_company' },
  { label: 'TK事业部', value: 'hz_tiktok' },
  { label: '无运营团队', value: 'none' },
]

export const operationOptionsFB = [
  { label: '无运营团队', value: 'none' },
  { label: '杭州FB运营组', value: 'hz_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
]

export const operationOptionsFBMCN = [
  { label: '杭州FB运营组', value: 'hz_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
]

export const trueOrFalseOptions = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
]

export const cmsOptions = [
  { label: '小五', value: 'XW' },
  { label: '亚创', value: 'AC' },
  { label: 'Adsense-HK', value: 'Adsense-HK' },
  { label: 'Adsense-US', value: 'Adsense-US' },
]

export const operationType = [
  { label: '影视', value: 'slice' },
  { label: '原创', value: 'original' },
  { label: '代运营', value: 'acting' },
  { label: '影视代运营', value: 'film_acting' },
  { label: '原创代运营', value: 'original_acting' },
  { label: '原创自制', value: 'original_own' },
  { label: '自运营', value: 'self' },
  { label: '自运营-符合变现地区所在国家', value: 'self_acc_country' },
  { label: '自运营-不符合变现地区所在国家', value: 'self_not_acc_country' },
]

export const operationOptions = [
  { label: 'YT事业部', value: 'jc_mcn' },
  { label: 'FB事业部', value: 'hz_fb' },
  { label: '国际事业部', value: 'hz_original' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城原创', value: 'jc_original' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '上交公司', value: 'hand_over_company' },
  { label: 'TK事业部', value: 'hz_tiktok' },
]

export const operationTextOptions = [
  { label: '影视事业部', value: '影视事业部' },
  { label: 'YT事业部', value: 'YT事业部' },
  { label: '原创事业部', value: '原创事业部' },
]

export const channelTypeOptions = [
  { label: '单开', value: 0 },
  { label: '子集', value: 1 },
  { label: '合集', value: 2 },
]

// 结算单审核节点
export const checkStatusOptions = [
  { label: '待客服审核', value: 1 },
  { label: '待财务审核', value: 2 },
  { label: '待客服二审', value: 3 },
  { label: '已推送', value: 4 },
  { label: '暂不结算', value: 5 },
]

// 发票状态
export const invoiceStatusOptions = [
  { label: '待上传', value: 0 },
  { label: '待审核', value: 1 },
  { label: '审核通过', value: 2 },
  { label: '已驳回', value: 3 },
  { label: '待生成', value: 7 },
  { label: '待发起', value: 4 },
  { label: '发起失败', value: 10 },
  { label: '待签字', value: 5 },
  { label: '已签字', value: 6 },
  { label: '已生成', value: 8 },
  { label: '已确认', value: 9 },
]

// 收款人类型
export const payeeTypeOptions = [
  { label: '企业', value: 0 },
  { label: '个人', value: 1 },
  { label: '境外', value: 2 },
]

// 发票类型
export const ticketTypeOptions = [
  { label: '数电专票', value: 0 },
  { label: '数电纸专', value: 1 },
  { label: '电专', value: 2 },
  { label: '纸专', value: 3 },
  { label: '数电普票', value: 4 },
  { label: '数电纸普', value: 5 },
  { label: '电普', value: 6 },
  { label: '纸普', value: 7 },
]

// 声明类型
export const statementTypeOptions = [
  { label: '正常', value: 'normal' },
  { label: '信息变更', value: 'contractChange' },
  { label: '新增汇款', value: 'newPayment' },
  { label: '存疑结算', value: 'doubtfulPayment' },
]

// 汇款途径
export const remittanceChannelOptions = [
  { label: '寰球通匯', value: 1 },
  { label: 'PingPong', value: 2 },
  { label: '本地汇款', value: 3 },
  { label: 'Payoneer', value: 4 },
  { label: '银行电汇', value: 5 },

]

export const contentTypeOptions = [
  { label: 'UGC', value: 'UGC' },
  { label: 'Partner-provided', value: 'Partner-provided' },
]

// 汇款状态
export const paymentStatusOptions = [
  { label: '待汇款', value: 0 },
  { label: '已汇款', value: 1 },
  { label: '部分已汇款', value: 2 },
]
// 汇款状态
export const paymentStatusOptionsStatement = [
  { label: '待汇款', value: 0 },
  { label: '已汇款', value: 1 },
  { label: '其他', value: '-1' },
]

// 分成模式
export const sharePatternOptions = [
  { label: '纯分成模式', value: '纯分成模式' },
  { label: '预支分成模式', value: '预支分成模式' },
  { label: '版权采买模式', value: '版权采买模式' },
  { label: '收益梯度模式', value: '收益梯度模式' },
  { label: '其他', value: '其他' },
]

// 平台
export const platformType = [
  { label: 'YT', value: 'YT' },
  { label: 'FB', value: 'FB' },
]

// 短信发送状态
export const sendSmsStatusOptions = [
  { label: '未发送', value: 0 },
  { label: '已发送待回执', value: 1 },
  { label: '已发送', value: 2 },
  { label: '发送失败', value: 3 },
]

export const videoSuffix = ['.mov', '.mpeg-1', '.mpeg-2', '.mpeg4', '.mp4', '.mpg', '.avi', '.wmv', '.mpegps', '.flv', '.3gpp', '.webm', '.dnxhr', '.prores', '.cineform']
export const thumbnailSuffix = ['.jpg', '.jpeg', '.png']
export const subtitleSuffix = ['.srt']

// 批量拆分子集模板链接
export const subsetTemplate = 'https://hzxw-video-mcn.oss-cn-hangzhou.aliyuncs.com/finance/%E6%A8%A1%E7%89%88/%E6%89%B9%E9%87%8F%E6%8B%86%E5%88%86%E5%AD%90%E9%9B%86%E6%A8%A1%E7%89%88.xlsx?OSSAccessKeyId=LTAI5tCbH26Eoiec58bofhLZ&Expires=36001734503319&Signature=q7gj4xTLsGYLKJH983pcA8SICrU%3D'

// 批量调差模板链接
export const adjustmentTemplate = 'https://silverdawn-hz.oss-cn-hangzhou.aliyuncs.com/translateExcel/%E6%89%B9%E9%87%8F%E8%B0%83%E5%B7%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx?OSSAccessKeyId=LTAI5tCbH26Eoiec58bofhLZ&Expires=2720001057&Signature=BQakOMAK1abp4sY5faFzu8efKk0%3D'

// 结算单变更汇款状态模板
export const changePaymentStatusTemplate = 'https://hzxw-video-mcn.oss-cn-hangzhou.aliyuncs.com/finance/%E6%A8%A1%E7%89%88/%E6%89%B9%E9%87%8F%E4%BF%AE%E6%94%B9%E6%B1%87%E6%AC%BE%E7%8A%B6%E6%80%81%E6%A8%A1%E6%9D%BF.xlsx?OSSAccessKeyId=LTAI5tCbH26Eoiec58bofhLZ&Expires=1000001731581014&Signature=Q9Zm5aeC5YELsc8E4Q5h3BmDYzg%3D'
