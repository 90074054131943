import '@/styles/styles.scss'
import '@/plugins'
import '@prettyy/ui/dist/css/index.css'
import 'vxe-table/lib/style.css'
import '@/styles/element-variables.scss'
import Vue from 'vue'
import VXETable from 'vxe-table'
import directives from '@/directives'
import XWUI from '@prettyy/ui'
import VTooltip from 'v-tooltip'
import router from '@/router'
import store from '@/store'
import App from './App.vue'
import vuetify from './plugins/vuetify'

VXETable.config({
  width: 150,
  loadingText: '加载中...',
  table: {
    tooltipConfig: {
      enterable: true,
    },
  },
})

Vue.use(VXETable)
Vue.use(directives)
Vue.use(XWUI)
Vue.use(VTooltip)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
