// 数据处理将childrenList转换为children
export function convertChildrenListToChildren(obj, filterList = ['component', 'field']) {
  if (Array.isArray(obj)) {
    return obj.map(item => convertChildrenListToChildren(item, filterList))
  } if (obj !== null && typeof obj === 'object') {
    if (obj.childrenList) {
      obj.children = convertChildrenListToChildren(obj.childrenList, filterList)
      delete obj.childrenList
      obj.children = obj.children.filter(item => !filterList.includes(item.type))
      if (obj.children.length === 0) {
        delete obj.children
      }
    }

    return obj
  }

  return obj
}

// 递归将将数据中不含有children的数据取出
export function extractWithoutChildren(obj) {
  let result = []
  if (Array.isArray(obj)) {
    obj.forEach(item => {
      result = result.concat(extractWithoutChildren(item))
    })
  } else if (obj !== null && typeof obj === 'object') {
    if (!obj.children) {
      result.push(obj)
    } else {
      result = result.concat(extractWithoutChildren(obj.children))
    }
  }

  return result
}

export function processData(arr) {
  // 定义结果对象
  const result = {}

  // 递归函数
  function traverse(items = []) {
    items.forEach(item => {
      // 如果目标路由不存在，则初始化它
      if (!result[item.route]) {
        result[item.route] = []
      }

      // 初始化字段对象
      // const fields = {}

      if (!Array.isArray(item.children)) return

      // 遍历子项以填充字段
      item.children.forEach(child => {
        // 处理子项
        if (child.type === 'field') {
          const fieldData = {
            text: child.name,
            value: child.path,
            view: false,
            export: false,
            edit: false,
          }

          // 遍历子项的操作
          if (child.children) {
            child.children.forEach(operation => {
              if (operation.name === '查看') {
                fieldData.view = true
              }
              if (operation.name === '导出') {
                fieldData.export = true
              }
              if (operation.name === '编辑') {
                fieldData.edit = true
              }
            })
          }

          // 添加到结果数组
          result[item.route].push(fieldData)
        }
      })

      // 如果当前项有子项，递归调用
      if (item.children) {
        traverse(item.children)
      }
    })
  }

  // 开始遍历传入的数组
  traverse(arr || [])

  return result
}
